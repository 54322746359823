const baseUrl = "https://dashboard.wizall.com";

export const login = async (data) => {
  const res = await fetch(`${baseUrl}/api/token/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const response = await res.json();
  return response;
};


export const resetPassword = async (data, token) => {
  const res = await fetch(`${baseUrl}/api/changepassword/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': "Token " +token 
    },
    body: JSON.stringify(data),
  });
  const response = await res.json();
  return response;
};
