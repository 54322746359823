import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Layout, Select } from "antd";
import DashboardServices from "./dashboardService";
import DashboardSn from "./dashboard_sn";
import DashboardBf from "./dashboard_bf";
import DashboardCi from "./dashboard_ci";
import DashboardMl from "./dashboard_ml";
import logo from "../logo.png";
import sn from "../../assets/img/sn.png";
import bf from "../../assets/img/bf.png";
import ci from "../../assets/img/ci.png";
import ml from "../../assets/img/ml.png";

const { Header, Content } = Layout;
const { Option } = Select;

export function Dashboard() {
  const currentProfile = localStorage.getItem("profile");
  const [profile, setProfile] = useState(currentProfile);

  const history = useHistory();
  const handleChange = (value) => setProfile(value);

  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };

  console.log("#", currentProfile);
  const flag = (profile) => {
    switch (profile) {
      case "access_to_report_wzservices":
        return null;
      case "access_to_report_bf":
        return <img src={bf} alt="img" className="flag" />;
      case "access_to_report_ci":
        return <img src={ci} alt="img" className="flag" />;
      case "access_to_report_ml":
        return <img src={ml} alt="img" className="flag" />;
      case "access_to_report_sn":
        return <img src={sn} alt="img" className="flag" />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Header>
        <Row>
          <Col span={22} offset={1}>
            <div className="menu">
              <img src={logo} alt="logo" className="logo" />
              <div className="logout">
                {currentProfile === "access_to_report_admin" ? (
                  <Select
                    defaultValue="access_to_report_wzservices"
                    style={{ width: 200 }}
                    onChange={handleChange}
                  >
                    <Option value="access_to_report_wzservices">
                      Wizall Services
                    </Option>
                    <Option value="access_to_report_bf">
                      Wizall Burkina Faso
                    </Option>
                    <Option value="access_to_report_ci">
                      Wizall Cote d'ivoire
                    </Option>
                    <Option value="access_to_report_ml">Wizall Mali</Option>
                    <Option value="access_to_report_sn">Wizall Sénégal</Option>
                  </Select>
                ) : null}
                {flag(profile)}{" "}
                <a href onClick={() => logout()}>
                  {" "}
                  Deconnexion{" "}
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Header>
      <Content>
        <Col span={20} offset={2}>
          <div className="pageHeader shadow">
            <p>
              Ce tableau de bord est une synthèse des KPI’S qui regroupe tous
              les distributeurs et une synthèse des KPI’S pour chaque commercial
              composé de deux rapports : Activité Globale et Activité par
              Commercial.
            </p>
          </div>
          <>
            {profile === "access_to_report_wzservices" ? (
              <DashboardServices />
            ) : profile === "access_to_report_bf" ? (
              <DashboardBf />
            ) : profile === "access_to_report_ci" ? (
              <DashboardCi />
            ) : profile === "access_to_report_ml" ? (
              <DashboardMl />
            ) : profile === "access_to_report_sn" ? (
              <DashboardSn />
            ) : profile === "access_to_report_admin" ? (
              <DashboardServices />
            ) : (
              history.push("/login")
            )}
          </>
        </Col>
      </Content>
    </Layout>
  );
}
