import * as React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import "../src/styles/globals.scss";
import { LoginPage } from "./pages/login";
import { Dashboard } from "./pages/dashboard";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/" component={LoginPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
