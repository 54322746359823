import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Col, message, Layout } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login, resetPassword } from "../../services/auth";
const key = "updatable";

export function LoginPage() {
  const [status, setStatus] = useState(false);
  const [token, setToken] = useState("");
  const history = useHistory();

  const onLogin = (values) => {
    message.loading({ content: "Loading...", key, duration: 0.5 });
    login(values).then((res) => {
      if (res.non_field_errors) {
        message.error({
          content:
            "Impossible de se connecter avec les informations d'identification fournies.",
          key,
          duration: 5,
        });
      } else {
        if (res.change_password) {
          setStatus(true);
          setToken(res.token);
        } else {
          const profile = findProfile(JSON.stringify(res.permissions));
          localStorage.setItem("profile", profile);
          history.push("/");
        }
      }
    });
  };

  const findProfile = (currentProfile) => {
    if (JSON.parse(currentProfile).includes("access_to_report_wzservices")) {
      return "access_to_report_wzservices";
    }
    if (JSON.parse(currentProfile).includes("access_to_report_admin")) {
      return "access_to_report_admin";
    }
    if (JSON.parse(currentProfile).includes("access_to_report_ci")) {
      return "access_to_report_ci";
    }
    if (JSON.parse(currentProfile).includes("access_to_report_ml")) {
      return "access_to_report_ml";
    }
    if (JSON.parse(currentProfile).includes("access_to_report_bf")) {
      return "access_to_report_bf";
    }
    if (JSON.parse(currentProfile).includes("access_to_report_sn")) {
      return "access_to_report_sn";
    }
  };

  const onResetPassword = (values) => {
    message.loading({ content: "Loading...", key, duration: 0.5 });
    resetPassword(values, token).then((res) => {
      if (res.code === 200) {
        message.success({ content: res.message, key, duration: 5 });
        setStatus(false);
      } else {
        message.error({ content: res.old_password, key, duration: 5 });
      }
    });
  };

  return (
    <>
      <Layout>
        <div className="login-page">
          {status ? (
            <Col lg={6} xs={16} style={{ margin: "auto" }}>
              <h2>Changer mon mot de passe de connexion</h2>
              <div className="card">
                <Form style={{ margin: "40px" }} onFinish={onResetPassword}>
                  <Form.Item
                    name="old_password"
                    rules={[{ required: true, message: "Champs requis" }]}
                  >
                    <Input.Password
                      className="form-control"
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Ancien mot de passe"
                    />
                  </Form.Item>

                  <Form.Item
                    name="new_password"
                    rules={[{ required: true, message: "Champs requis" }]}
                  >
                    <Input.Password
                      className="form-control"
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Nouveau mot de passe"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn btn-lg btn-primary"
                    >
                      {" "}
                      Se connecter{" "}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          ) : (
            <Col lg={6} xs={16} style={{ margin: "auto" }}>
              <div className="card">
                <Form
                  style={{ margin: "40px" }}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onLogin}
                >
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: "Champs requis" }]}
                  >
                    <Input
                      className="form-control"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Login"
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Champs requis" }]}
                  >
                    <Input.Password
                      className="form-control"
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Mot de passe"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn btn-lg btn-primary"
                    >
                      Se connecter
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          )}
        </div>
      </Layout>
    </>
  );
}
